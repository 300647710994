import Chai from 'types/chai'

export const firebaseConfig: Chai.FirebaseConfig = process.env.NEXT_PUBLIC_FIREBASE_API_KEY
  ? {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    messagingVapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  }
  : undefined
